import * as React from "react";
import { Trans } from 'gatsby-plugin-react-i18next';

const SupportSection = () => {
  return <>
    <section className="w-full relative z-20 -mb-10">
      <div className="absolute w-full h-full top-0 left-0 -z-10">
        <img className="w-full h-full object-cover" srcSet="/images/page-bg@2x.webp 2x, /images/page-bg.webp 1x"
          src="/images/page-bg.webp" width="1600" height="502" alt="page-bg" />
      </div>
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid"><span></span><span></span><span></span><span></span></div>
      </div>

      <div className="container pt-110 lg:pt-185 sc-up-mb relative">
        <div className="text-center">
          <h1 className="heading-1 heading-1-2">
            <Trans>Customer and Enterprise support</Trans>
          </h1>
        </div>
        <div className="space-custom"></div>
      </div>

      <div className="w-full -z-10"><img className="w-full" srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
        src="/images/oval_BG.webp" width="1600" height="235" alt="oval-top" /></div>
    </section>
    <section className="relative">
      <div className="bg-blue-bg-dark">
        <div className="container z-10">
          <ul className="section-custom-top flex flex-wrap justify-center relative z-20">
            <li className="card-item-1 card-half card card-b card-anim">
              <div className="box-item bg-blue-bg-dark py-36 px-40">
                <div className="w-48 h-48 mx-auto">
                  <img className="image-sd"
                    srcSet="/images/icons/discord@2x.webp 2x, /images/icons/discord.webp 1x"
                    src="/images/icons/discord.webp" width="48" height="48" alt="discord" />
                </div>
                <div className="mt-28 mb-auto text-24">
                  <p className="font-medium text-orange-light uppercase tracking-widest">
                    <Trans>CHAT WITH US</Trans>
                  </p>
                  <p className="mt-28 leading-normal">
                    <Trans>Join our Discord and chat with us directly.</Trans>
                    <br className="hidden xl:block" />
                    <Trans>We are always ready to help.</Trans>
                  </p>
                </div>
                <div className="mt-30">
                  <a href="https://discord.gg/dcSpark" target="_blank" rel="noopener" className="btn-blue btn-blue-big w-200">
                    <Trans>Join our Discord</Trans>
                  </a>
                </div>
              </div>
            </li>
            <li className="card-item-1 card-half card card-b animation-delay-300 card-anim">
              <div className="box-item bg-blue-bg-dark py-36 px-40">
                <div className="w-48 h-48 mx-auto">
                  <img className="image-sd"
                    srcSet="/images/icons/email@2x.webp 2x, /images/icons/email.webp 1x"
                    src="/images/icons/email.webp" width="48" height="48" alt="youtube" />
                </div>
                <div className="mt-28 mb-auto text-24">
                  <p className="font-medium text-orange-light uppercase tracking-widest">
                    <Trans>Contact us</Trans>
                  </p>
                  <p className="mt-28 leading-normal">
                    <Trans>Feel free to contact us, if you need some help or have some other questions.</Trans>
                  </p>
                </div>
                <div className="mt-30">
                  <a href="/contact" className="btn-blue btn-blue-big w-200">
                    <Trans>Contact us</Trans>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
        <div className="layout-grid z-10"><span></span><span></span><span></span><span></span></div>
      </div>
    </section>
  </>
};

export default SupportSection;
