import * as React from "react";
import { graphql } from "gatsby";
import FooterSection from "../components/FooterSection";
import MilkHeader from "../components/MilkHeader";
import PageMeta from "../components/PageMeta";
import SupportSection from "../components/SupportSection";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";

const SupportPage = () => {
  const { t } = useTranslation();
  const langContext = React.useContext(I18nextContext);
  return (
    <>
      <main>
        <PageMeta
          title={t("Customer and Enterprise support • Milkomeda")}
          description={t(
            "Milkomeda brings the most used smart-contract programming language across blockchains. Develop once, deploy everywhere."
          )}
          locale={t("en_US")}
          language={langContext.language}
        />
        <MilkHeader />
        <div>
          <SupportSection />
        </div>
      </main>
      <FooterSection
        footerImage={"/images/footer-bg@2x.webp 2x, /images/footer-bg.webp 1x"}
      />
    </>
  );
};

export default SupportPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
